(function() {
    angular.module('EntrakV5').controller('slGatewayController', slGatewayController);

    function slGatewayController($scope, $rootScope, $q, Service, KEY, Api, $timeout) {
        console.log('slGatewayController');

        var caller = Api.createApiCaller();
        $scope.btnStatus = {};
        $scope.slGateways = [];

    /* first load */
        $rootScope.loadingPage = 1;
        caller.call(Api.getSLGateways()).then(function(res){
            $scope.slGateways = res;$scope.slGateways = [];//TODOricky api no ready
            $rootScope.loadingPage--;
        }, function(err){
            if (err === KEY.ignore)
                return;
            $rootScope.loadingPage--;
            alert(Service.translate("error.generalGetDataFail"));
        });
    /* first load */

        $scope.createSLGateways = function(){
            $rootScope.loadingPage++;
            $scope.btnStatus.saving = true;
            caller.call(Api.createSLGateways()).then(function(res){
                $scope.slGateways.push(res);
                $scope.btnStatus.saving = false;
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                $rootScope.loadingPage--;
                alert(Service.translate("error.generalSaveFail"));
            });
        }

        $scope.deleteSLGateway = function(g){
            alert("This feature is coming soon...");return;
            $rootScope.deletePopup.show("slGateway.popup.deleteSLGateway", "slGateway.popup.deleteSLGatewayDesc", hub.serialId, function(){//TODOricky api
                caller.call(Api.delete(g)).then(function(res){
                    Service.deleteArrItem($scope.slGateways, g);
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    alert(Service.translate("error.generalDeleteFail"));
                });
            });
        }

        $scope.$on('$destroy', function() {
            console.log("slGatewayController destroy");
            caller.cancel();
        });
    }
})();
